<template>
  <div class="bg_view">
    <van-nav-bar left-text="返回" left-arrow fixed placeholder @click-left="onClickLeft">
    </van-nav-bar>
    <!-- 列表 -->
    <div class="list_view" v-show="dataList.length > 0">
      <table border="0" cellspacing="0" cellpadding="0">
        <thead class="sticy_top">
          <tr>
            <td :style="`${index != 0 ? 'border-left: none' : ''}`" v-for="(item, index) in listTypes" :key="`key_${index}`" :class="`${index == 0 ? 'sticy_left' : ''}`">
              <div class="list_row_view" style="font-weight: bold;">
                <span>{{ item.name }}</span>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in dataList" :key="`key_${index}`">
            <td class="sticy_left" :style="`border-left: 1px solid #E6E6E6;overflow: hidden;max-width:100px`">
              <div class="list_row_view">{{ item.memberNickname }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.userStatus == 1? "绑定中" : "已解绑" }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.bindDate }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.unbindDate }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.totalPayPrice }}</div>
            </td>
            <td>
              <div class="list_row_view">{{ item.totalBrokerage }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-box" v-if="dataList.length == 0">
      <img class="empty-img" :src="require('@/assets/icon_empty.png')" alt="">
      <div class="empty-text">暂无数据</div>
    </div>
    <div class="list_hide_bottom_bar"></div>
  </div>
</template>

<script>
import API from '@/common/axios';
import { Toast } from 'vant';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh');
export default {
  data() {
    return {
      listTypes: [
        { name: "团员名称" },
        { name: "团员状态"},
        { name: "绑定时间"},
        { name: "解绑时间"},
        { name: "累计实付金额"},
        { name: "累计佣金"},
      ],
      dataList: []
    };
  },
  mounted() {
    document.title = "团员列表"
    this.getData();
  },
  methods: {
    onClickLeft() {
      this.$router.replace('/groupManager/home');
    },
    getData(isSort) {
      const loading = Toast.loading({
        loadingType: "spinner",
        position: "middle",
        message: "加载中..."
      });
      API({
        url: "/h5/team/getLeagueMemberList.do",
        data: {
          type: 1
        }
      }).then(res => {
        loading.clear();
        if (res.success == 1) {
          this.dataList = res.data;
        } else {
          this.dataList = [];
          Toast(res.errMsg || "请重试！");
        }
      }).catch(_ => {
        this.dataList = [];
        loading.clear();
        Toast("服务异常，请刷新页面重试！");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .van-nav-bar {
  .van-icon {
    color: black;
  }

  .van-nav-bar__text {
    color: black;
  }
}

.bg_view {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}

.list_view {
  margin: 10px;
  flex: 1;
  overflow: auto;
  position: relative;
  padding-bottom: 10px;

  table {
    table-layout: auto;
    width: auto;
  }

  td {
    border: 1px solid #E6E6E6;
  }

  .list_row_view {
    background-color: #F5F5F7;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    padding: 0 10px;
    min-width: 90px;
    word-break: break-all;

    .sort_view {
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      /deep/ .van-icon {
        color: #999999;
        display: block;
        font-size: 12px;
        font-weight: bold;

        &:last-child {
          margin-top: -4px;
        }
      }

      .select_icon {
        color: #784CFA;
      }
    }

  }

  .sticy_left {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .sticy_top {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  tbody {
    td {
      border-top: none;
      border-left: none;
      background-color: #fff;
    }

    .list_row_view {
      color: #666;
      background-color: white;
    }
  }

}

.list_hide_bottom_bar {
  position: absolute;
  bottom: 0;
  height: 20px;
  background-color: white;
  width: 100%;
  z-index: 4;
}

.empty-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  .empty-img {
    width: 140px;
  }

  .empty-text {
    text-align: center;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 4px;
  }
}
</style>